export const environment = {
	production: false,
	appUrl: 'https://compliance.staging.zonarsystems.net',
	authEnv: 'qa',
	prefix: 'qa',
	name: 'staging',
	region: 'NA',
	eldxApplicationId: '05abeb96-e24e-4846-8703-035411d45fb0',
	// TODO: Setup your application's authentication variables: see
	// https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
	// for instructions on how to do that.
	auth: {
		clientID: 'DZb4ewK640C7WDUPPJSE78yjJzS23xy4',
		domain: 'qa-login.zonarsystems.net',
		audience: 'http://apiqa.zonarsystems.net/',
		applicationId: '7b848b7a-621c-4690-8f18-a59bf17b6edc',
		defaultZonarRole: '75a05a6a-cc6d-4ed3-82a8-c1e19217488e',
		useRefreshTokens: true
	},
	urls: {
		baseApi: `https://api-qa.zonarsystems.net`,
		externalApi: 'https://api-qa.zonarsystems.net/core/v1',
		hosBackendApi: 'https://hos-reports-7qmwslz3vq-uw.a.run.app',
		legacyEntityApi: `https://legacy-entity-api-7qmwslz3vq-uw.a.run.app`,
		eldx: 'https://eldx.qa.zonarsystems.net',
		udlApi: 'https://udl-service-7qmwslz3vq-uw.a.run.app'
	},
	accountsWithAdmin: ['mbl2021'],
	excludeAccounts: [],
	useHereV3: true,
    datadog: {
        applicationId: 'c345ac2d-a240-464a-bc85-e3696c38716e',
        clientToken: 'pub3b706123189f56e5f8b1e610cf55e6f8',
        site: 'us5.datadoghq.com',
        service: 'compliance-dashboard',
    },
	devCycle: {
		clientId: 'dvc_client_931944ae_1bdd_4034_a82f_081de48407a9_a5e52b5'
	},
	ciDevUrl: undefined
};

export const httpInterceptList =  [
	// core entity api base urls. these are used by the auth module to make calls to the user-api
	environment.urls.baseApi + '/*',
	environment.urls.externalApi + '/*',
	environment.urls.hosBackendApi + '/*',
	environment.urls.legacyEntityApi + '/*',
	environment.urls.udlApi + '/*',
	`/*`
];
